import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Image, Spinner } from 'react-bootstrap';
import styles from './AboutUs.style';
import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/about-us.png';
import Section from '../../Components/Section/Section';
import Innvoative from "../../assets/images/innovative.png";
import locationIcon from "../../assets/images/location.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import career from "../../assets/images/career.png";
import Dot from "../../assets/images/dot.png";
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading';
const AboutUs: React.FC = () => {
  const breadcrumbs: string[] = ['Home', 'About Us'];
  const title: string = 'About Us';
  const info: string = "";
  const imageUrl: string = TitleImage; // Corrected imageUrl

  

  const navigate = useNavigate()

  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, []);

 


  
  return (
    <div className="about-us common-page">
    
      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className="aboutUs" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>
         <Row>
          <Col>
          <Heading text="About The Company" alignment="left" />
          <p style={styles.text}>
          Welcome to <b>Sajilo Life,</b> where our mission is clear: <b>Makes Life Easier.</b> As a leading retail-first marketplace, we are committed to streamlining your shopping and service needs, delivering everything you require directly to your doorstep.
          </p>
          </Col>
         </Row>
         <Row>
          <Col>
          <Heading text="Our Mission" alignment="left" />
          <p style={styles.text}>
          At Sajilo Life, we are transforming the retail and service landscape by offering a platform that emphasizes convenience, quality, and customer satisfaction. Our goal is to simplify your life by providing a comprehensive range of products and services while empowering local retailers and connecting them with local buyers.
          </p>
          </Col>
         </Row>
         <Row>
          <Col>
          <Heading text="Our Offerings
" alignment="left" />
          <ul style={styles.text}>
          <li><b>Daily Essentials:</b> Access a wide range of groceries and household items to meet your everyday needs.</li>
          <li><b>Food Delivery:</b> Enjoy your favorite meals delivered promptly to your location.</li>
          <li><b>Fresh Vegetables:</b> Receive the freshest produce, ensuring you always have healthy options available.</li>
          <li><b>Fashion:</b> Stay stylish with the latest trends in clothing, accessories, and footwear.</li>
          <li><b>Electronics:</b> Discover the latest gadgets and electronic devices to keep you connected and entertained.</li>
          <li><b>Hardware:</b> Find quality tools and hardware supplies for all your home improvement projects.</li>
          <li><b>Home Services: </b>From repairs to plumbing and wiring, our trusted professionals are ready to assist with any home maintenance needs.</li>

          </ul>
          </Col>
         </Row>

         <Row>
          <Col>
          <Heading text="Convenient Shopping" alignment="left" />
          <p style={styles.text}>
          Purchase your favorite products from your preferred or nearest shop. We offer both options: you can either <b>pick up your order from the store</b> or <b>have it delivered to your location.</b>  Choose the convenience that suits you best, whether it’s picking up your orders from our store or having them delivered right to your doorstep.

          </p>
          </Col>
         </Row>


         <Row>
          <Col>
          <Heading text="Empowering Local Retail" alignment="left" />
          <p style={styles.text}>
          We are dedicated to supporting and empowering local retailers. By partnering with local businesses, we help them reach a broader audience and thrive in a competitive market. Our platform provides these retailers with the tools and visibility they need to succeed, fostering a diverse and vibrant marketplace.
          </p>
          </Col>
         </Row>


         <Row>
          <Col>
          <Heading text="Connecting Local Buyers and Sellers" alignment="left" />
          <p style={styles.text}>
          <b>Sajilo Life</b> serves as a bridge between local retailers and buyers, creating a community where local businesses can flourish and customers can enjoy the best products and services their area has to offer. By choosing <b>Sajilo Life,</b> you are supporting your local economy and contributing to a stronger, more connected community.
          </p>
          </Col>
         </Row>


         <Row>
          <Col>
          <Heading text="Maximizing Seller Profits" alignment="left" />
          <p style={styles.text}>
          We focus on maintaining low commission rates on sales, ensuring that our sellers can enjoy maximum profits from their sales. This approach allows local businesses to thrive and reinvest in their growth, benefiting the entire community.
          </p>
          </Col>
         </Row>



         <Row>
          <Col>
          <Heading text="Our Values" alignment="left" />
          <ul style={styles.text}>
         <li> <b>Customer-Centric:</b> Your satisfaction is our highest priority. We are committed to providing exceptional service and support to ensure a positive experience.</li>
         <li> <b>Innovation:</b> We continuously seek to improve and innovate, leveraging the latest technologies to enhance our platform and services.</li>
         <li> <b>Integrity:</b> We operate with transparency and honesty, building trust with our customers and vendors through ethical practices.</li>

          </ul>
          </Col>
         </Row>


         <Row>
          <Col>
          <Heading text="Future Services" alignment="left" />
          <p style={styles.text}>We are constantly evolving to meet your needs. Here are some exciting services we plan to introduce soon:
          </p>
          <ul style={styles.text}>
          <li><b>Invoice and POS:</b> Our platform supports IRD-compliant billing and POS software, allowing you to handle all your sales transactions seamlessly. Enjoy comprehensive billing services directly from our platform without the need to pay extra for additional billing and POS software.</li>
          <li><b>Direct Payment Link:</b> Our platform allows you to create direct payment links for dues and invoices, making it easy for your customers to pay. Simply generate a payment link through our system and share it with your customers. They can then complete their payments securely and conveniently, all through our platform. </li>
          <li><b>E-commerce Platform and Custom Domain:</b> Our platform enables you to create your very own e-commerce site and host it on a custom domain, providing a unique online presence tailored to your brand. With integrated payment processing, you can securely handle transactions directly through your site. Our system also supports delivery management, ensuring your products reach customers efficiently. Additionally, comprehensive billing features allow you to manage invoices and payments seamlessly. This all-in-one solution simplifies the process of setting up and running an e-commerce business, saving you time and money while offering a professional and personalized platform for your business.</li>
          <li><b>Buy Now, Pay Later:</b> Enjoy the flexibility of purchasing items now and paying for them later.</li>
          <li><b>Credit to Retail Buyers:</b> Access credit facilities to make your shopping experience even more convenient.</li>
          <li><b>Ride Sharing:</b> Simplify your commute with our reliable ride-sharing service. Whether you’re heading to work, running errands, or meeting friends, our service offers a convenient and cost-effective way to get around. Enjoy the flexibility of shared rides, reduce your carbon footprint, and make your daily travel hassle-free.</li>
          <li><b>Micro Lending:</b> Partnering with banks to offer micro-lending options to support your financial needs. This will help seller and buyer to meet immediate need whenever they require.</li>
          <li><b>Classifieds:</b> A platform for buying and selling a variety of items within your community.</li>
          <li><b>Property Rental Management:</b> Streamline your property rental process with our comprehensive management services.</li>

          </ul>
          </Col>
         </Row>

         
         <Row>
          <Col>
          <Heading text="Join Us on Our Journey" alignment="left" />
          <p style={styles.text}>
          At <b>Sajilo Life,</b> we are more than just a marketplace; we are a community. We invite you to join us on our journey as we redefine convenience and quality in retail and services. Whether you’re shopping for essentials, ordering food, or booking home services, Sajilo Life is here to make your life easier.
Thank you for choosing Sajilo Life. Together, let’s make life simpler and more enjoyable.

          </p>
          </Col>
         </Row>

         {/* <Row className="mt-5">
          <Col>
            <Heading text="Innovative Leadership" alignment='left'/>
            <Row className="mt-4">
              <Col sm="3" className="text-center">
                <div style={styles.imgWrap} className="mb-2">
                  <Image src={Innvoative} style={styles.img} />
                </div>
                <div style={styles.contentWrap}>
                  <h3 style={styles.title} className='mb-0'>
                  Lorem ipsum
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum
                  </p>
                </div>
              </Col>
              <Col sm="3" className="text-center">
                <div style={styles.imgWrap} className="mb-2">
                  <Image src={Innvoative} style={styles.img} />
                </div>
                <div style={styles.contentWrap}>
                  <h3 style={styles.title} className='mb-0'>
                  Lorem ipsum
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum
                  </p>
                </div>
              </Col>
              <Col sm="3" className="text-center">
                <div style={styles.imgWrap} className="mb-2">
                  <Image src={Innvoative} style={styles.img} />
                </div>
                <div style={styles.contentWrap}>
                  <h3 style={styles.title} className='mb-0'>
                  Lorem ipsum
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum
                  </p>
                </div>
              </Col>
              <Col sm="3" className="text-center">
                <div style={styles.imgWrap} className="mb-2">
                  <Image src={Innvoative} style={styles.img} />
                </div>
                <div style={styles.contentWrap}>
                  <h3 style={styles.title} className='mb-0'>
                  Lorem ipsum
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          </Row>
          <Row className="mt-5">
          <Col>
            <Heading text="Career Opportunities" alignment='left'/>
            <Row className="mt-4">
              <Col sm="4">
                <div style={styles.careerImgWrap} className="mb-4">
                  <Image src={career} style={styles.img} />
                </div>
                <div style={styles.contentWrap} className='px-3 mt-2'>
                  <h6 style={styles.subTitle} className="mb-2">Lorem ipsum</h6>
                  <h3 style={styles.title} className='mb-2'>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </Col>
              
              <Col sm="4">
                <div style={styles.careerImgWrap} className="mb-4">
                  <Image src={career} style={styles.img} />
                </div>
                <div style={styles.contentWrap} className='px-3 mt-2'>
                  <h6 style={styles.subTitle} className="mb-2">Lorem ipsum</h6>
                  <h3 style={styles.title} className='mb-2'>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </Col>
              <Col sm="4">
                <div style={styles.careerImgWrap} className="mb-4">
                  <Image src={career} style={styles.img} />
                </div>
                <div style={styles.contentWrap} className='px-3 mt-2'>
                  <h6 style={styles.subTitle} className="mb-2">Lorem ipsum</h6>
                  <h3 style={styles.title} className='mb-2'>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </Col>
             
            </Row>
          </Col>
          </Row> */}
        </Container>
      </Section>
    </div>
  );
};

export default AboutUs;
