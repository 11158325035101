import React, { useEffect, useState } from "react";
import styles from "./NearestShop.style";
import Section from "../Section/Section";
import { Col, Container, Row, Card, Image, Spinner } from "react-bootstrap";
import Heading from "../Heading/Heading";
import bookmark from "../../assets/images/bookmark.png";
import shop1 from "../../assets/images/default-shop.png";
// import CIcon from "@coreui/icons-react";
// import * as icon from "@coreui/icons";
import locationIcon from "../../assets/images/location.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Nearest_Shops } from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaStar } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import noProduct from "../../assets/images/no-product.png";
import { base_url } from "../../Api/ApiConst";
import { useTranslation } from "react-i18next";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import lottieNearByShop from "../../assets/lotties/nearByShops.json";
import { Player } from "@lottiefiles/react-lottie-player";
import NoDataFound from "../NoDataFound/NoDataFound";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1901 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

interface Category {
  id: string;
  store_name: string;
  store_type: string;
  distance_in_km: string;
  language_data: any;
  store_rating: any;
  logo: string;
}

const NearestShop: React.FC = () => {
  const { t } = useTranslation();

  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(true);
  // const [pincode,setPincode] = useState<any>
  // const [mainId,setMainId] = React.useState<string | null>("");
  const selectedCatgeory: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const [stores, setStores] = React.useState<Category[]>([]);
  const languageId: any = useSelector((state: RootState) => state.lan);
  const navigate = useNavigate();
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );

  const dispatch = useDispatch();
  const userPincode = useSelector((state: RootState) => state.Location.pincode);

  // useEffect(() => {
  //   setLatitude(localStorage.getItem("latitude"))
  //   setLongitude(localStorage.getItem("longitude"))

  // }, []);

  // useEffect(() =>{
  //   setMainId(localStorage.getItem("mainId"))
  // },[mainId])
  // console.log(latitude);
  // console.log(longitude);
  // console.log(mainId)

  const fetchShops = async () => {
    try {
      const payload = {
        business_type: selectedCatgeory?.name,
        lat: userLatitude,
        long: userLongitude,
        pincode: userPincode,
      };
      const response = await Nearest_Shops(payload);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        // console.log(response.data, "stores=====>");
        setStores(response.data.store_list);
      } else {
        console.log("Error fetching Nearest Shops");
      }
    } catch (error) {
      console.log("Error fetching Nearest Shops", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCatgeory?.name && userLatitude && userLongitude) {
      fetchShops();
    }
  }, [selectedCatgeory?.name, userLatitude, userLongitude]);

  const handleClick = (item: any) => {
    localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, item?.id);
    dispatch(SET_SELECTED_STORE_SUBCATEGORY({}));
    let languageData = undefined;
    if (item?.language_data && item?.language_data.length > 0) {
      languageData = item?.language_data[0].store_name;
    }
    const state = {
      store_name: item?.store_name,
      store_type: item?.store_type,
      distance_in_km: item?.distance_in_km,
      language_data: languageData,
      store_rating: item?.store_rating,
      store_logo: item?.logo,
      storeId: item?.id,
    };
    navigate("/store", { state: state });
  };

  // console.log(latitude)
  // console.log(selectedCatgeory?.name);

  // if(stores.length === 0){
  //   return(
  //     <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  //   <h3 style={styles.noProduct}>
  //     <Image src={noProduct} className="mx-2" style={{ width: 32, height: 32 }} /> No products found</h3>
  // </div>
  //   )
  // }

  console.log(userLatitude, "latitude");

  return (
    <>
      {stores.length > 0 && (
        <Section className="near mb-5">
          <Container>
            <Row className="inner-wrap">
              <Col className="align-items-center justify-content-between d-flex">
                <Heading
                  text={t("homeScreen.titleSectionNearestShop")}
                  alignment="left"
                />
                {!loading && stores.length >= 3 && (
                  <Link style={styles.viewAll} to="/near-by-shops">
                    {t("commonHeader.viewAll")}
                    {/* View All  */}
                  </Link>
                )}
              </Col>
            </Row>
            {loading && (
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <Player
                    src={lottieNearByShop}
                    loop
                    autoplay
                    className="player"
                    style={{ height: "150px", width: "150px" }}
                  />
                </Col>
              </Row>
            )}
            {!loading && stores.length === 0 && (
              // <Row className="noData">
              //   <Col className="d-flex align-items-center justify-content-center">
              //     <h3 style={styles.noProduct}>
              //       <Image
              //         src={noProduct}
              //         className="mx-2"
              //         style={{ width: 32, height: 32 }}
              //       />{" "}
              //       No Shops found in your area
              //     </h3>
              //   </Col>
              // </Row>
              <NoDataFound title="No Shops found in your area" />
            )}
            {!loading && stores.length !== 0 && (
              <Row>
                <Col>
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {stores &&
                      stores.map((item) => (
                        <div
                          className="d-flex align-items-center singleShop"
                          style={styles.singleShopWrap}
                          key={item.id}
                          onClick={() => handleClick(item)}
                        >
                          <div
                            style={styles.shopImageWrap}
                            className="shopImgWrap"
                          >
                            {item.logo ? (
                              <Image
                                src={`${base_url}/${item.logo}`}
                                style={styles.shopImage}
                              />
                            ) : (
                              <Image src={shop1} style={styles.shopImage} />
                            )}
                          </div>
                          <div className="px-2 shopInfo">
                            <h2
                              style={styles.shopName}
                              className="mb-1 shopName"
                            >
                              {item?.language_data?.length > 0
                                ? languageId === "ne"
                                  ? item?.language_data[0]?.store_name
                                  : item?.store_name
                                : item?.store_name}
                            </h2>
                            <div className="d-flex align-items-center mb-3 loc-wrap">
                              {/* <h3 style={styles.shopLocation} className="mb-0 d-flex align-items-center">
                          <Image src={locationIcon} style={styles.locationIcon} className="mx-1" />
                          Location
                        </h3> */}
                              <p
                                className="mb-0 d-flex"
                                style={styles.shopRating}
                              >
                                <FaStar className="m-1" />
                                {Math.round(item?.store_rating)}
                              </p>
                            </div>
                            <div className="d-flex align-items-center mb-1 dist-supply">
                              <h3 style={styles.shopDistance} className="mb-0">
                                {item?.distance_in_km}Km
                              </h3>
                              <div
                                className="supply-wrap"
                                style={{
                                  ...styles.bookmarkBg,
                                  backgroundImage: `url(${bookmark})`,
                                }}
                              >
                                <p className="mb-0" style={styles.supplyMethod}>
                                  {item?.language_data?.length > 0
                                    ? languageId === "ne"
                                      ? item?.language_data[0]?.store_type
                                      : item?.store_type
                                    : item?.store_type}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Carousel>
                </Col>
              </Row>
            )}
          </Container>
        </Section>
      )}
    </>
  );
};

export default NearestShop;
