import React, { useEffect, useState } from "react";
import {
  Nav,
  Container,
  Row,
  Col,
  Image,
  Alert,
  Modal,
  Button,
} from "react-bootstrap";
import Order from "../../Components/Orders/Orders";
import { getStyles } from "./UserProfile.style";
import Payments from "../../Components/Payments/Payments";
import Coupons from "../../Components/Coupons/Coupons";
import Wishlist from "../../Components/Wishlist/Wishlist";
import Profile from "../../Components/Profile/Profile";
import Settings from "../../Components/Settings/Settings";
import Help from "../../Components/Help/Help";
import SwitchStore from "../../Components/SwitchStore/SwitchStore";
import Section from "../../Components/Section/Section";
import Heading from "../../Components/Heading/Heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaBox,
  FaCreditCard,
  FaHeart,
  FaHome,
  FaPowerOff,
  FaStore,
  FaTag,
  FaUser,
} from "react-icons/fa";
import OrderImage from "../../assets/images/order.svg";
import Logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { My_Store } from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { ACTIVE_USER, CLEAR_ACTIVE_ROLE, INITIAL_TAB } from "../../Redux/User";
import StoreProfile from "../../Components/StoreProfile/StoreProfile";
import { toast } from "react-toastify";
import { setLoginStatus } from "../../Redux/loginSlice";
import { CLEAR_CART } from "../../Redux/Cart";
import LogoutModal from "../../Components/LogoutModal/LogoutModal";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { SELECTED_TAB } from "../../Redux/Wishlist";
import { FaRectangleList, FaUserTie } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import BuyAgain from "../../Components/BuyAgain/BuyAgain";
import PickupWhereYouLeftFrom from "../../Components/PickupWhereYouLeftFrom/PickupWhereYouLeftFrom";
import ProductReviews from "../../Components/ProductReviews/ProductReviews";
import StoreReviews from "../../Components/StoreReviews/StoreReviews";

const UserProfile: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  // const [role, setRole] = useState<string>('user'); // Initial role state
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [lastActiveTab, setLastActiveTab] = useState<string>("Switch to Store");
  const [showSwitchMessage, setShowSwitchMessage] = useState<boolean>(false); // State to control modal visibility
  const dispatch = useDispatch();
  const [number, setNumber] = useState<string | null>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const role = useSelector((state: RootState) => state.User.ActiveRole);
  const activeTab = useSelector((state: RootState) => state.User.ActiveTab);
  // const initialTab = role === 'user' ? 'Profile' : 'StoreProfile';
  // const [activeTab, setActiveTab] = useState<string>(initialTab);
  const [storeStatus, setStoreStatus] = useState<any>("");

  const [status, setStatus] = useState<any>("");
  const navigate = useNavigate();

  const handleTabChange = (tabName: string) => {
    dispatch(INITIAL_TAB(tabName));
  };
  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const handleSwitchToStore = () => {
    dispatch(ACTIVE_USER("store"));
    setLastActiveTab("Switch to User");
    setShowSwitchMessage(true);
    dispatch(INITIAL_TAB("StoreProfile"));
    console.log(role, "role----------->");
  };

  const handleSwitchToUser = () => {
    dispatch(ACTIVE_USER("user"));
    setLastActiveTab("Switch to Store");
    setShowSwitchMessage(true);
    dispatch(INITIAL_TAB("Profile"));
    console.log(role, "role----------->");
  };

  const handleAPI = async (check:any) => {
    try {
      const response = await My_Store(userToken);
      if (response && response.status === 200) {
        // console.log(response.data, "storeImage============>");
        setNumber(response?.data?.my_stores[0]?.id);
        setStatus(response?.data?.my_stores[0]?.admission);
        localStorage.setItem(
          STORAGE_CONSTANTS.STORE_NAME,
          response?.data?.my_stores[0]?.store_name
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.STORE_STATUS,
          response?.data?.my_stores[0]?.admission
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.STORE_ID,
          response?.data?.my_stores[0]?.id
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.STORE_LOGO,
          response?.data?.my_stores[0]?.logo
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.AREA_PIN,
          response?.data?.my_stores[0]?.service_area_pin_code
        );

        if (response?.data?.my_stores[0]?.admission === "Approve") {
          if(check === "ord"){

            navigate("/orders");
          }else if(check === "prod"){
            navigate("/products");
          }else if(check === "off"){
            navigate("/offers");
          }
        } else {
          toast.error("Store is not approved");
        }
      } else {
        console.log("Error fetching My Store details");
      }
    } catch (error) {
      console.log("Error fetching My Store details", error);
    }
  };

  useEffect(() => {
    // Automatically close the modal after 3 seconds
    if (showSwitchMessage) {
      const timer = setTimeout(() => {
        setShowSwitchMessage(false);
      }, 1000);

      // Clear the timer if the component unmounts or if showSwitchMessage becomes false
      return () => clearTimeout(timer);
    }
  }, [showSwitchMessage]);

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    let USERTOKEN = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN)
    if(!USERTOKEN){
      navigate("/login")
    }
  }, []);

  useEffect(() => {
    if (userToken) {
      const handleStore = async () => {
        try {
          const response = await My_Store(userToken);
          if (response && response.status === 200) {
            console.log(response.data, "storeImage============>");
            setNumber(response.data.my_stores[0].id);
            setStatus(response.data.my_stores[0].admission);
            localStorage.setItem(
              STORAGE_CONSTANTS.STORE_NAME,
              response.data.my_stores[0].store_name
            );
            localStorage.setItem(
              STORAGE_CONSTANTS.STORE_STATUS,
              response.data.my_stores[0].admission
            );
            localStorage.setItem(
              STORAGE_CONSTANTS.STORE_ID,
              response.data.my_stores[0].id
            );
            localStorage.setItem(
              STORAGE_CONSTANTS.STORE_LOGO,
              response.data.my_stores[0].logo
            );
            localStorage.setItem(
              STORAGE_CONSTANTS.AREA_PIN,
              response.data.my_stores[0].service_area_pin_code ?? ""
            );
          } else {
            console.log("Error fetching My Store details");
          }
        } catch (error) {
          console.log("Error fetching My Store details", error);
        }
      };
      handleStore();
    }
  }, [userToken, status]);

  const handleOrder = () => {
    handleAPI("ord")
   
  };

  const handleProduct = async () => {
   handleAPI("prod")
  };

  const handleOffer = () => {
    handleAPI("off")
  };

  return (
    <Section className="userProfile">
      <Container>
        <Row>
          <Heading text={t("userProfile.profile")} alignment="left" />
        </Row>
      </Container>
      <Container className="below">
        <Row>
          <Col sm={3} className="tabCol">
            <Nav variant="pills" className="flex-column user-profile-tab">
              <Nav.Item>
                {role === "user" && (
                  <>
                    <Nav.Link
                      eventKey="Order"
                      active={activeTab === "Order"}
                      onClick={() => handleTabChange("Order")}
                    >
                      <span>
                        <FaBox className="profile-icon" />
                      </span>
                      {t("userProfile.order")}
                    </Nav.Link>
                    {/* <Nav.Link eventKey="Payments" active={activeTab === 'Payments'} onClick={() => handleTabChange('Payments')}>
                                            <FaCreditCard className="profile-icon" />
                                            Payments
                                        </Nav.Link> */}
                    {/* <Nav.Link eventKey="Coupons" active={activeTab === 'Coupons'} onClick={() => handleTabChange('Coupons')}>
                                            <FaStore className="profile-icon" />
                                            Coupons
                                        </Nav.Link> */}
                    <Nav.Link
                      eventKey="Wishlist"
                      active={activeTab === "Wishlist"}
                      onClick={() => handleTabChange("Wishlist")}
                    >
                      <span>
                        {" "}
                        <FaHeart className="profile-icon" />
                      </span>
                      {t("userProfile.wishlist")}
                    </Nav.Link>
                    <Nav.Link
                      eventKey="Profile"
                      active={activeTab === "Profile"}
                      onClick={() => handleTabChange("Profile")}
                    >
                      <span>
                        <FaUser className="profile-icon" />
                      </span>
                      {t("userProfile.profile")}
                    </Nav.Link>
                    {/* <Nav.Link eventKey="Settings" active={activeTab === 'Settings'} onClick={() => handleTabChange('Settings')}>
                                           <span>  <FaStore className="profile-icon" /></span>
                                            Settings
                                        </Nav.Link> */}
                    {/* <Nav.Link eventKey="Help" active={activeTab === 'Help'} onClick={() => handleTabChange('Help')}>
                                            <span> <FaStore className="profile-icon" /></span>
                                            Help
                                        </Nav.Link> */}
                    {number ? (
                      <Nav.Link onClick={handleSwitchToStore}>
                        <span>
                          {" "}
                          <FaStore className="profile-icon" />
                        </span>
                        {t("userProfile.switchToStore")}
                      </Nav.Link>
                    ) : (
                      <Nav.Link as={Link} to="/register">
                        <span>
                          {" "}
                          <FaStore className="profile-icon" />
                        </span>
                        {t("userProfile.storeRegister")}
                      </Nav.Link>
                    )}
                    {/* <Nav.Link eventKey="Logout" active={activeTab === 'Logout'} onClick={() => handleTabChange('Logout')}>
                                           <span>  <FaStore className="profile-icon" />
                                            Logout
                                        </Nav.Link> */}
                    <Nav.Link onClick={handleLogout}>
                      <span>
                        {" "}
                        <FaPowerOff className="profile-icon" />
                      </span>
                      {t("userProfile.logout")}
                    </Nav.Link>
                  </>
                )}

                {role === "store" && (
                  <>
                    <Nav.Link
                      eventKey="StoreProfile"
                      active={activeTab === "StoreProfile"}
                      onClick={() => handleTabChange("StoreProfile")}
                    >
                      <span>
                        <FaUser className="profile-icon" />
                      </span>
                      {t("userProfile.storeProfile")}
                    </Nav.Link>
                    <Nav.Link as={Link} to="/store-dashboard">
                      <span>
                        {" "}
                        <FaHome className="profile-icon" />
                      </span>
                      {t("userProfile.storeDashboard")}
                    </Nav.Link>

                    <div onClick={handleOrder} className="store-links">
                      <span>
                        <FaBox className="profile-icon" />
                      </span>
                      {t("userProfile.orderList")}
                    </div>

                    <div onClick={handleProduct} className="store-links">
                      <span>
                        <FaRectangleList className="profile-icon" />
                      </span>
                      {t("userProfile.productList")}
                    </div>

                    <div onClick={handleOffer} className="store-links">
                      <span>
                        <FaTag className="profile-icon" />
                      </span>
                      {t("userProfile.offersList")}
                    </div>

                    <Nav.Link onClick={handleSwitchToUser}>
                      <span>
                        <FaUserTie className="profile-icon" />
                      </span>
                      {t("userProfile.switchToUser")}
                    </Nav.Link>

                    <Nav.Link onClick={handleLogout}>
                      <span>
                        {" "}
                        <FaPowerOff className="profile-icon" />
                      </span>
                      {t("userProfile.logout")}
                    </Nav.Link>
                  </>
                )}
              </Nav.Item>
              {/* Other Nav.Items */}
            </Nav>
          </Col>
          <Col sm={9}>
            {/* Role switch message */}
            <Modal
              show={showSwitchMessage}
              onHide={() => setShowSwitchMessage(false)}
              className="switchModal"
              centered
            >
              <Modal.Body>
                <Image src={Logo} style={{ width: 120 }} />
                {role === "user"
                  ? t("userProfile.switchToUser")
                  : t("userProfile.switchToStore")}
              </Modal.Body>
            </Modal>
            {role === "user" && (
              <>
                {activeTab === "Order" && <Order />}
                {activeTab === "Payment" && <Payments />}
                {activeTab === "Coupons" && <Coupons />}
                {activeTab === "Wishlist" && <Wishlist />}
                {activeTab === "Payment" && <Payments />}

                {activeTab === "Profile" && <Profile />}
                {activeTab === "Settings" && <Settings />}
                {activeTab === "SwitchStore" && <SwitchStore />}
                {activeTab === "Help" && <Help />}

              </>
            )}
            {role === "store" && (
              <>
                {/* Render store components */}
                {activeTab === "StoreProfile" && <StoreProfile />}
              </>
            )}
          </Col>
        </Row>
        <LogoutModal
          show={showLogoutModal}
          onClose={() => setShowLogoutModal(false)}
          onLogout={handleLogout}
        />
                                        {activeTab === "Order" && <BuyAgain /> }
                                        {activeTab === "Wishlist" && <PickupWhereYouLeftFrom />}
                                        {activeTab === "Profile" && <ProductReviews />}
                                        {activeTab === "Profile" && <StoreReviews /> }

      </Container>
    </Section>
  );
};

export default UserProfile;
