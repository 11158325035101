import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Image,
  Spinner,
  Overlay,
  Badge,
} from "react-bootstrap";
import { getStyles } from "./NearByShops.style";
import TitleBar from "../../Components/TitleBar/TitleBar";
import NearByShop from "../../assets/images/shop-near-you.png";
import Section from "../../Components/Section/Section";
import shop1 from "../../assets/images/default-shop.png";
import locationIcon from "../../assets/images/location.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bookmark from "../../assets/images/supply.png";
import Dot from "../../assets/images/dot.png";
import { FaArrowRight, FaSearch, FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { Nearest_Shops, Search_Stores } from "../../Api";
import { useNavigate } from "react-router-dom";
import noProduct from "../../assets/images/no-product.png";
import _ from "lodash";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { Link } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import Popover from "react-bootstrap/esm/Popover";
import { useTranslation } from "react-i18next";
import { base_url } from "../../Api/ApiConst";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import lottieNearByShop from "../../assets/lotties/nearByShops.json";
import { Player } from "@lottiefiles/react-lottie-player";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { Rating } from "react-simple-star-rating";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import debounce from "lodash.debounce";
const isWithin48Hours = (lastProductDate: string): boolean => {
  const currentDate = new Date();
  const productCreatedDate = new Date(lastProductDate);
  const timeDiff = currentDate.getTime() - productCreatedDate.getTime();
  const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert to hours
  return hoursDiff <= 48;
};
const NearByShops: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const mainName = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const languageId: any = useSelector((state: RootState) => state.lan);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const title = t("commonHeader.shopNearToYou");
  const styles = getStyles(isMobile);
  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    mainName?.language_data?.length === 0
      ? mainName?.name
      : languageId === "ne"
      ? mainName?.language_data[0]?.name
      : mainName?.name,
  ];
  const info = "Lorem ipsum";
  const imageUrl = NearByShop; // Corrected imageUrl

  interface Category {
    id: string;
    store_name: string;
    store_type: string;
    distance_in_km: string;
    language_data: any;
    logo: any;
    store_rating: any;
    last_product_created_date: string;
  }

  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const [initialStores, setInitialStores] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = React.useState<any>(true);
  const [suggestions, setSuggestions] = React.useState<any>([]);
  const selectedCatgeory: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const [stores, setStores] = React.useState<Category[]>([]);
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const [sortBy, setSortBy] = useState<string>("");
  const [searchText, setSearchText] = useState<any>("");
  const userPincode = useSelector((state: RootState) => state.Location.pincode);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLatitude(localStorage.getItem(STORAGE_CONSTANTS.LATITUDE));
    setLongitude(localStorage.getItem(STORAGE_CONSTANTS.LONGITUDE));
    window.scrollTo(0, 0);
  }, []);

  const sortData = [
    {
      id: 1,
      sortType: "store_name_ascending",
      name: t("storeSortModal.shopNameAsc"),
    },
    {
      id: 2,
      sortType: "store_name_descending",
      name: t("storeSortModal.shopNameDesc"),
    },
    {
      id: 3,
      sortType: "distance_ascending",
      name: t("storeSortModal.distanceAsc"),
    },
    {
      id: 4,
      sortType: "distance_descending",
      name: t("storeSortModal.distanceDesc"),
    },
    {
      id: 5,
      sortType: "rating_ascending",
      name: "Rating (Ascending)",
    },
    {
      id: 6,
      sortType: "rating_descending",
      name: "Rating (Descending)",
    },
    {
      id: 7,
      sortType: "new_arrival",
      name: "New Arrival",
    },
  ];

  const fetchShops = async (sortType: any) => {
    try {
      setLoading(true);

      const payload = {
        business_type: selectedCatgeory?.name,
        lat: userLatitude,
        long: userLongitude,
        pincode: userPincode,
      };

      const sortPayload = {
        business_type: selectedCatgeory?.name,
        lat: userLatitude,
        long: userLongitude,
        pincode: userPincode,
        sort_value: sortType,
      };

      const response = await Nearest_Shops(sortType ? sortPayload : payload);
      if (response && response.status === 200) {
        console.log(response.data, "shops=======>");
        setStores(response.data.store_list);
        setInitialStores(response.data.store_list);
      } else {
        console.log("Error fetching Nearest Shops");
      }
    } catch (error) {
      console.log("Error fetching Nearest Shops", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (item: any) => {
    setSortBy(item);
    if (item?.sortType) {
      fetchShops(item?.sortType);
    } else {
      setStores([...stores]?.sort((a: any, b: any) => b.id - a.id));
    }
  };

  const debouncedFetchShops = useCallback(_.debounce(fetchShops, 500), [
    selectedCatgeory?.name,
    userLatitude,
    userLongitude,
  ]);

  useEffect(() => {
    debouncedHandleSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    if (
      selectedCatgeory?.name !== null &&
      userLatitude !== null &&
      userLongitude !== null
    ) {
      debouncedFetchShops("");
    }
  }, [
    selectedCatgeory?.name,
    userLatitude,
    userLongitude,
    debouncedFetchShops,
  ]);

  const handleClick = (item: any) => {
    localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, item.id);
    dispatch(SET_SELECTED_STORE_SUBCATEGORY({}));
    let languageData;
    if (item.language_data && item.language_data.length > 0) {
      languageData = item.language_data[0].store_name;
    }
    const state = {
      store_name: item?.store_name || item?.name,
      store_type: item?.store_type,
      distance_in_km: item?.distance_in_km,
      language_data: languageData,
      store_logo: item?.logo,
      storeId: item?.id,
      store_rating: item?.store_rating,
    };
    navigate("/store", { state: state });
  };

  const debouncedHandleSearch = debounce(async (searchText: any) => {
    if (searchText) {
      try {
        setLoading(true);
        const response = await Search_Stores(
          isSearch,
          searchText,
          userLatitude,
          userLongitude
        );
        if (response && response.status === 200) {
          const formattedStores = response.data.product_list.map(
            (store: any) => ({
              id: store?.id,
              name: store?.store_name,
              logo: store?.logo, // This is important, `name` is the key used in the items
            })
          );
          setSuggestions(formattedStores);
        } else {
          console.log("Error fetching Nearest Shops");
        }
      } catch (error) {
        console.error("Error fetching Nearest Shops", error);
      } finally {
        setLoading(false);
      }
    } else {
      fetchShops(""); // Fallback call
    }
  }, 500);

  const handleSearch = async () => {
    if (searchText) {
      try {
        setLoading(true);
        const response = await Search_Stores(
          false,
          searchText,
          userLatitude,
          userLongitude
        );
        if (response && response.status === 200) {
          console.log(response.data, "shops=======>");
          setStores(response.data);
          setInitialStores(response.data);
        } else {
          console.log("Error fetching Nearest Shops");
        }
      } catch (error) {
        console.log("Error fetching Nearest Shops", error);
      } finally {
        setLoading(false);
      }
    } else {
      fetchShops("");
    }
  };

  // const handleSearch = () => {
  //   // const searchResponse = [...products].filter((item:any) => {
  //   //   return item
  //   // })
  //   console.log(searchText, "searchText");
  //   if (searchText?.trim() !== "") {
  //     const filteredData = [...initialStores].filter(
  //       (item: Category) =>
  //         item.store_name.toLowerCase().includes(searchText.toLowerCase()) ||
  //         item.store_type.toLowerCase().includes(searchText.toLowerCase()) ||
  //         (item.distance_in_km + "".toLowerCase()).includes(
  //           searchText.toLowerCase()
  //         )
  //       //  (item.distance_in_km).includes()(searchText)
  //     );
  //     console.log(filteredData, "filteredData=========>");
  //     setStores(filteredData);
  //   } else {
  //     setStores(initialStores);
  //     // console.log(filteredData,"filteredData=========>")
  //   }
  // };
  //  useEffect(() => {
  //     const handleClickOutside = (event: MouseEvent) => {
  //       if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
  //         setShowSortPopover(false);
  //       }
  //     };

  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [popoverRef]);

  // console.log(initialStores,"hgghi");

  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };

  // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === "Enter") {
  //     handleSearch();
  //   }
  // };

  return (
    <>
      {/* <TitleBar
        breadcrumbs={breadcrumbs}
        title={title}
        imageUrl={imageUrl}
      /> */}

      <Section className="storeView" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>

          <Row style={styles.searchRow}>
            <ReactSearchAutocomplete
              className="mr-sm-2 search-input"
              items={suggestions} 
              onSearch={(input: string) => setSearchText(input)} 
              onSelect={(item) => handleClick(item)} 
              formatResult={(item: any) => (
                <div className="searchSuggestion">
                 <span>
  <img
    src={item?.logo ? `${base_url}/${item.logo}` :  `${shop1}`}
    style={{ width: "2rem" }}
    alt={item?.name ?? "Default Image"}
  />
</span>

                  <span>{item?.name}</span>{" "}
                </div>
              )} 
              autoFocus
            />
            <Button variant="outline-primary search-btn" onClick={handleSearch}>
              <FaSearch />
            </Button>
          </Row>
        </Container>
      </Section>

      {/* <FormControl
                type="text"
                placeholder={t("commonHeader.globalSearch")}
                className="mr-sm-2 search-input"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleKeyDown}
              /> */}

      <Section
        className="nearByShop"
        style={{ paddingTop: 50, paddingBottom: 100 }}
      >
        <Container>
          {!loading && stores.length !== 0 && (
            <div className="d-flex align-items-center justify-content-end flex-row mb-3">
              <div ref={target}>
                <Button
                  title="Sort"
                  style={styles.btnSort}
                  variant="outline-primary"
                  onClick={() => setShowSortPopover(!showSortPopover)}
                >
                  <Image
                    src={Sort}
                    style={styles.btnSortIcon}
                    className="sortImage"
                  />
                </Button>
                <Overlay
                  show={showSortPopover}
                  target={target.current}
                  placement="bottom"
                  containerPadding={20}
                  container={document.body}
                  onHide={handleOverlayClose}
                  rootClose
                >
                  <Popover id="popover-contained">
                    {/* <Popover.Header as="h3">Sort By</Popover.Header> */}
                    <Popover.Body>
                      <ul
                        style={{ listStyleType: "none", padding: 0 }}
                        className="sortPopover"
                      >
                        {sortData &&
                          sortData.map((ele) => {
                            return (
                              <li>
                                <Button
                                  variant="link"
                                  onClick={() => handleSort(ele)}
                                >
                                  {ele?.name}
                                </Button>
                              </li>
                            );
                          })}
                      </ul>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>
          )}
          {loading && stores.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Player
                src={lottieNearByShop}
                loop
                autoplay
                className="player"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
          ) : !loading && stores.length === 0 ? (
            // <Row className="noData">
            //   <Col className="d-flex align-items-center justify-content-center">
            //     <h3 style={styles.noProduct}>
            //       <Image src={noProduct} className="mx-2" style={{ width: 32, height: 32 }} />{" "}
            //       {t("commonHeader.noShopsFoundInYourArea")}

            //     </h3>
            //   </Col>
            // </Row>
            <NoDataFound title={t("commonHeader.noShopsFoundInYourArea")} />
          ) : (
            <Row className="shopRow">
              {stores.length > 1 &&
                stores.map((item) => {
                  const isRecent = isWithin48Hours(
                    item.last_product_created_date
                  );
                  return (
                    <Col sm={4} key={item.id} style={styles.shopCol}>
                      <Row style={styles.innerRow}>
                        <div
                          className="d-flex align-items-center singleShop"
                          style={styles.singleShopWrap}
                          onClick={() => handleClick(item)}
                        >
                          <div
                            style={styles.shopImageWrap}
                            className="sm-shopImageWrap"
                          >
                            <Image
                              src={
                                item.logo ? `${base_url}/${item.logo}` : shop1
                              }
                              style={styles.shopImage}
                            />
                          </div>

                          <div className="px-2 shopInfo" style={styles.left}>
                            <h2
                              style={styles.shopName}
                              className="mb-2 shopName"
                            >
                              {item?.language_data?.length > 0
                                ? languageId === "ne"
                                  ? item?.language_data[0]?.store_name
                                  : item?.store_name
                                : item?.store_name}
                            </h2>

                            <div className="d-flex align-items-center mb-2 shopLocWrap">
                              <Image
                                src={Dot}
                                style={{ width: 5, height: 5 }}
                                className="mx-2 hide-xs"
                              />
                              <h3 style={styles.shopDistance} className="mb-0">
                                {item.distance_in_km}Km
                              </h3>
                            </div>
                            <div>
                              <Rating
                                initialValue={item?.store_rating}
                                allowHover={false}
                                readonly={true}
                                size={22}
                                style={{ marginBottom: "7rem" }}
                              />
                              {isRecent && (
                                <Badge className="mt-3 blink_me">New</Badge>
                              )}
                            </div>
                            <div className="d-flex align-items-center my-3 ">
                              <div
                                style={{
                                  ...styles.bookmarkBg,
                                  backgroundImage: `url(${bookmark})`,
                                }}
                                className="supply-method"
                              >
                                <p className="mb-0" style={styles.supplyMethod}>
                                  {item?.language_data?.length > 0
                                    ? languageId === "ne"
                                      ? item?.language_data[0]?.store_type
                                      : item?.store_type
                                    : item?.store_type}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  );
                })}
            </Row>
          )}
        </Container>
      </Section>
    </>
  );
};

export default NearByShops;
