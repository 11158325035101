// Home.tsx

import React, { useEffect, useState } from "react";
import MainCategory from "../../Components/MainCategories/MainCategories";
import NearestShop from "../../Components/NearestShop/NearestShop";
import SubcategoryWiseProduct from "../../Components/SubcategoryWiseProduct/SubcategoryWiseProduct";
import TopDeals from "../../Components/TopDeals/TopDeals";
import Offers from "../../Components/Offers/Offers";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useNavigate } from "react-router-dom";
import PickupWhereYouLeftFrom from "../../Components/PickupWhereYouLeftFrom/PickupWhereYouLeftFrom";
import { useDispatch } from "react-redux";
import { Searched_Type } from "../../Redux/Search";
// import Offers from '../../Components/Offers/Offers';
const Home: React.FC = () => {
  const [Token, setToken] = useState<any>("");
  const navigate = useNavigate();
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(Searched_Type("Products"));
  }, []);

  return (
    <>
      <MainCategory />
      <NearestShop />
      <Offers />
      <SubcategoryWiseProduct />
      {/* <TopDeals /> */}
      {/* <PickupWhereYouLeftFrom/> */}
    </>
  );
};

export default Home;
