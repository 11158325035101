// Subcategories.tsx

import React, { useEffect, useState } from "react";
import styles from "./StoreSubcategory.style";
import { Store_View, Sub_categories } from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { base_url } from "../../Api/ApiConst";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import { useTranslation } from "react-i18next";
import { SET_SELECTED_SUBCATEGORY_ID } from "../../Redux/productId";
import { SET_STORE_VIEW_SEARCH } from "../../Redux/Search";

export interface Category {
  id: string;
  name: string;
  // Add other properties as needed
}

export interface SubcategoriesProps {
  onSubcategorySelect: (subcateId: string) => void;
}

interface SubcategoryProps {
  data: Category[]; // Assuming Category interface is defined globally
}

const StoreSubcategory: React.FC<SubcategoriesProps> = ({
  onSubcategorySelect,
}) => {
  interface response {
    id: string;
    name: string;
    category_image: string;
    language_data: any;
  }
  const { t, i18n } = useTranslation();

  //redux states
  const dispatch = useDispatch();
  const mainId: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );

  //component states
  const [subCategory, setSubCategory] = React.useState<response[]>([]);
  const [selectedId, setSelectedId] = useState("offers");
  const languageId: any = useSelector((state: RootState) => state.lan);
  const [productData, setProductData] = React.useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [storeId, setStoreId] = useState<string | null>("");

  const selectedSubCategory = useSelector(
    (state: RootState) => state.User.selectedSubCategory
  );

  const searchSubcategory = useSelector(
    (state: RootState) => state.productId.selectedSubcategory_id
  );

  // console.log("selectedSubCategory_____________", selectedSubCategory)

  // useEffect(() => {
  //   setID(localStorage.getItem("section_id"));
  // }, []);

  useEffect(() => {
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.SHOP_ID));
    // dispatch(SET_SELECTED_SUBCATEGORY_ID(""))
  }, []);

  const fetchProducts = async () => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const payload = {
        store_id: storeId,
        main_category: mainId?.id,
      };

      const response = await Store_View(payload);
      if (response && response.status === 200) {
        setSubCategory(
          response.data.sub_category_list.sort((a: any, b: any) =>
            a.id < b.id ? -1 : 1
          )
        );

        let intialSelectedSubCategory: any;
        if (selectedSubCategory?.id) {
          intialSelectedSubCategory = selectedSubCategory;
        } else {
          intialSelectedSubCategory = response.data.sub_category_list.sort(
            (a: any, b: any) => (a.id < b.id ? -1 : 1)
          )[0];
        }

        onSubcategorySelect(intialSelectedSubCategory?.id);
        setSelectedId(intialSelectedSubCategory?.id);
      } else {
        console.log("Error while fetching Products");
      }
    } catch (error) {
      console.log("Error while fetching Products", error);
    } finally {
      setLoading(false); // Set loading to false when fetching finishes
    }
  };

  useEffect(() => {
    if (mainId?.id && storeId) {
      fetchProducts();
    }
  }, [mainId?.id, storeId]);

  const handleClick = (item: any) => {
    onSubcategorySelect(item.id);
    setSelectedId(item.id);
    dispatch(SET_SELECTED_SUBCATEGORY_ID(""));
    dispatch(SET_STORE_VIEW_SEARCH(""));
  };

  return (
    <div>
      <ul style={styles.catWrap} className="subcat">
        <li
          onClick={() => handleClick({ id: "offers" })}
          style={{
            color: selectedId === "offers" ? "#075cfe" : "#282828",
            fontFamily: "var(--primary-font-medium)",
            fontSize: 18,
            listStyle: "none",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "#d9d9d9",
            paddingBottom: 10,
            marginBottom: 0,
            paddingTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="subcatName">Offers</div>
            {searchSubcategory === "" && selectedId === "offers" && (
              <div
                style={{
                  width: "9px",
                  height: "25px",
                  backgroundColor: "#075cfe",
                }}
              ></div>
            )}
          </div>
        </li>
        {subCategory &&
          subCategory.map((item, index) => {
            return (
              <li
                key={item.id}
                onClick={() => handleClick(item)}
                style={{
                  color:
                    searchSubcategory !== ""
                      ? "#282828"
                      : selectedId === item.id
                      ? "#075cfe"
                      : "#282828",
                  fontFamily: "var(--primary-font-medium)",
                  fontSize: 18,
                  listStyle: "none",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  borderBottomColor: "#d9d9d9",
                  paddingBottom: 10,
                  marginBottom: 0,
                  paddingTop: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="hide-lg">
                    <div className="subcatImgWrap">
                      <img
                        src={`${base_url}${item.category_image}`}
                        className="subcatImg"
                      />
                    </div>
                    <div className="mobile-subcatName">
                      {" "}
                      {item.language_data.length === 0
                        ? item?.name
                        : languageId === "ne"
                        ? item?.language_data[0]?.name
                        : item?.name}
                    </div>
                  </div>
                  <div className="subcatName">
                    {item.language_data.length === 0
                      ? item?.name
                      : languageId === "ne"
                      ? item?.language_data[0]?.name
                      : item?.name}
                  </div>
                  {searchSubcategory === ""
                    ? selectedId === item?.id && (
                        <div
                          style={{
                            width: "9px",
                            height: "25px",
                            backgroundColor: "#075cfe",
                          }}
                        ></div>
                      )
                    : ""}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default StoreSubcategory;
