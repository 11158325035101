import React, { useEffect, useRef, useState } from "react";
import Subcategories from "../Subcategories/Subcategories";
import ProductList, { ProductListProps } from "../ProductList/ProductList";
import Section from "../Section/Section";
import {
  Button,
  Col,
  Container,
  Image,
  Overlay,
  Row,
  Spinner,
} from "react-bootstrap";
import Heading from "../Heading/Heading";
import {
  Search_Store_Product,
  Store_offers,
  Store_SubCategory_View,
  Store_View,
} from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import StoreSubcategory from "../StoreSubcategory/StoreSubcategory";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import lottieProductList from "../../assets/lotties/productList.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import { SET_SELECTED_SUBCATEGORY_ID } from "../../Redux/productId";
import { SET_STORE_VIEW_SEARCH } from "../../Redux/Search";
import { useNavigate } from "react-router-dom";
import Popover from "react-bootstrap/esm/Popover";
import Sort from "../../assets/images/sort.png";
import styles from "./StoreSubcategoryWiseProduct.style";
import Alert from "react-bootstrap/esm/Alert";

interface Category {
  id: string;
  product_name: string;
  product_image: string;
  PRDPrice: number;
  PRDWeight: string;
}
interface ProductType {
  id: number;
  product_name: string;
  product_description: string;
  product_image: string | null;
  current_price: {
    discount_type: string;
    discount_val: number;
    current_price: number;
    current_usd_price: number;
    offer_valid_to: string;
  };
  product_vendor_store: {
    id: number;
    store_name: string;
    contact_number: string;
    address: string;
    city: string;
  };
  // Add other relevant product fields here
}

const StoreSubcategoryWiseProduct = () => {
  const { t, i18n } = useTranslation();
  // State to track the selected subcategory
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>("");
  const [productData, setProductData] = React.useState<Category[]>([]);
  const [productDataForSearch, setProductDataForSearch] = React.useState<
    Category[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [storeId, setStoreId] = useState<string | null>("");
  const [currentPage, setCurrentPage] = useState<any>(1);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<string>("");
  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);

  const dispatch = useDispatch();

  // redux states
  const searchTerm: any = useSelector(
    (state: RootState) => state.Search.StoreViewSearch
  );

  // const storeId: any = useSelector(
  //   (state: RootState) => state.Store.storesId
  // );
  // const mainId: any = useSelector((state: RootState) => state.mainCategory.selectedMainCategory);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.SHOP_ID));
  }, []);

  // Handler function to update the selected subcategory
  const handleSubcategorySelect = (subcateId: string) => {
    console.log(subcateId, "sdasdadasdas");
    setSelectedSubcategory(subcateId);

    // Clear previous product data to prevent showing stale data
    setProductData([]);
    setProductDataForSearch([]);

    if (subcateId === "offers") {
      fetchStoreOffers(); // Call fetchStoreOffers if the subcategory is 'offers'
    } else {
      fetchProduct(""); // Call fetchProducts if subcategory is anything else
    }
  };

  const fetchStoreOffers = async () => {
    setLoading(true);
    const userToken = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
    if (!userToken) {
      console.error("User token is not available.");
      setLoading(false);
      return;
    }
    try {
      const response = await Store_offers(userToken, storeId);
      console.log("Response from Store_offers:", response);

      if (response && response.status === 200) {
        console.log("data=====>", response.data);
        const offerProducts = response.data.list_of_offers;
        setProductData(offerProducts);
        setProductDataForSearch(offerProducts);
        // console.log("offerProducts", offerProducts);
      } else {
        console.error("Error while fetching offer products:", response); // Log the full response for debugging
      }
    } catch (error) {
      console.error("Error while fetching offer products:", error); // Log the error object for more details
    } finally {
      setLoading(false);
    }
  };

  

  const handleSearch = (searchTerm: string) => {
    if (searchTerm?.trim() !== "") {
      const fetchSearchProducts = async () => {
        try {
          const response = await Search_Store_Product(searchTerm, storeId);
          if (response && response.status === 200) {
            console.log(
              response?.data?.search_product_list[0]?.product_subcategory,
              "search------------------->"
            );
            setProductData(response?.data?.search_product_list);
            dispatch(
              SET_SELECTED_SUBCATEGORY_ID(
                response?.data?.search_product_list[0]?.product_subcategory
              )
            );
          } else {
            console.log("Error fetching Store Product Details");
          }
        } catch (error) {
          console.log("Error fetching Store Product Details", error);
        }
      };
      fetchSearchProducts();
    } else {
      fetchProduct("");
      dispatch(SET_SELECTED_SUBCATEGORY_ID(""));
    }
  };

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     setLoading(true); // Set loading to true when fetching starts
  //     try {
  //       const response = await Store_View(storeId,mainId);
  //       if (response && response.status === 200) {
  //         console.log(response.data,">>>>>>>>>>>>>>>>>>>>>>");
  //         setProductData(response.data.product_list)

  //       } else {
  //         console.log("Error while fetching Products");
  //       }
  //     } catch (error) {
  //       console.log("Error while fetching Products", error);
  //     } finally {selectedSubcategory
  //       setLoading(false); // Set loading to false when fetching finishes
  //     }
  //   };
  // )}

  //   const fetchProducts = async () => {
  //     setLoading(true); // Set loading to true when fetching starts
  //     try {
  //       const response = await Store_View(storeId,mainId?.id);
  //       if (response && response.status === 200) {
  //         console.log(response.data,">>>>>>>>>>>>>>>>>>>>>>");
  //         setProductData(response.data.product_list)

  //       } else {
  //         console.log("Error while fetching Products");
  //       }
  //     } catch (error) {
  //       console.log("Error while fetching Products", error);
  //     } finally {
  //       setLoading(false); // Set loading to false when fetching finishes
  //     }
  //   };

  // useEffect(() =>{

  //   if (mainId?.id && storeId) {
  //     fetchProducts();
  //   }
  // },[mainId?.id,storeI

  const sortData = [
    {
      id: 1,
      sortType: "product_ascending",
      name: t("offerSortModal.productNameAsc"),
    },
    {
      id: 2,
      sortType: "product_descending",
      name: t("offerSortModal.productNameDesc"),
    },
    {
      id: 3,
      sortType: "price_ascending",
      name: t("storeSortModal.priceAsc"),
    },
    {
      id: 4,
      sortType: "price_descending",
      name: t("storeSortModal.priceDesc"),
    },
    {
      id: 5,
      sortType: "rating_ascending",
      name: "Rating (Ascending)",
    },
    {
      id: 6,
      sortType: "rating_descending",
      name: "Rating (Descending)",
    },
    {
      id: 7,
      sortType: "new_arrival",
      name: "New Arrival",
    },

  ];

  const fetchProduct = async (sortType: any) => {
    setLoading(true);
    try {
      let payload = {
        store_id: storeId,
        sub_category: selectedSubcategory,
      };

      let sortPayload = {
        store_id: storeId,
        sub_category: selectedSubcategory,
        sort_value: sortType,
      };

      const response = await Store_SubCategory_View(
        sortType ? sortPayload : payload
      );
      if (response && response.status === 200) {
        setProductData(response.data.product_list);
        setProductDataForSearch(response.data.product_list);
      } else {
        console.log("Error while fetching Products");
      }
    } catch (error) {
      console.log("Error while fetching Products", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (item: any) => {
    setSortBy(item);
    if (item?.sortType) {
      fetchProduct(item?.sortType)
    } else {
      setProductData(() => [...productData]);
    }
  };

  // const fetchProduct = async () => {
  //   setLoading(true); // Set loading to true when fetching starts

  //   try {
  //     const response = await Store_SubCategory_View(
  //       storeId,
  //       selectedSubcategory
  //     );
  //     if (response && response.status === 200) {
  //       // console.log(response.data,"kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
  //       setProductData(response.data.product_list);
  //       setProductDataForSearch(response.data.product_list);
  //     } else {
  //       console.log("Error while fetching Products");
  //     }
  //   } catch (error) {
  //     console.log("Error while fetching Products", error);
  //   } finally {
  //     setLoading(false); // Set loading to false when fetching finishes
  //   }
  // };

  useEffect(() => {
    if (selectedSubcategory) {
      fetchProduct("");
    }
  }, [selectedSubcategory]);

  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };

  const products: Category[] = selectedSubcategory ? productData : [];

  return (
    <Section className="subcateoryWiseProduct">
      <Container>
        {!loading && productData.length !== 0 && (
          <div className="d-flex align-items-center justify-content-end flex-row mb-3">
            <div ref={target}>
              <Button
                title="Sort"
                style={styles.btnSort}
                variant="outline-primary"
                onClick={() => setShowSortPopover(!showSortPopover)}
              >
                <Image src={Sort} className="sortImage" />
              </Button>
              <Overlay
                show={showSortPopover}
                target={target.current}
                placement="bottom"
                containerPadding={20}
                container={document.body}
                onHide={handleOverlayClose}
                rootClose
              >
                <Popover id="popover-contained">
                  {/* <Popover.Header as="h3">Sort By</Popover.Header> */}
                  <Popover.Body>
                    <ul
                      style={{ listStyleType: "none", padding: 0 }}
                      className="sortPopover"
                    >
                      {sortData &&
                        sortData.map((ele: any) => {
                          return (
                            <li key={ele?.id}>
                              <Button
                                variant="link"
                                onClick={() => handleSort(ele)}
                              >
                                {ele?.name}
                              </Button>
                            </li>
                          );
                        })}
                    </ul>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </div>
        )}

        <Row style={{ marginTop: 30, marginBottom: 20 }}>
          <Col>
            <Heading text={t("commonHeader.products")} alignment="left" />
          </Col>
        </Row>
        <Row className="row-subcateoryWiseProduct">
          <Col
            sm={3}
            style={{
              borderRightWidth: 1,
              borderRightStyle: "solid",
              paddingRight: 0,
              borderRightColor: "var(--gray-light)",
            }}
          >
            <StoreSubcategory onSubcategorySelect={handleSubcategorySelect} />
          </Col>
          <Col sm={9}>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <Player
                  src={lottieProductList}
                  loop
                  autoplay
                  className="player"
                  style={{ height: "300px" }}
                />
              </div>
            ) : (
              <ProductList
                selectedSubcategory={selectedSubcategory}
                products={products}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default StoreSubcategoryWiseProduct;
