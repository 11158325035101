import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Image, Spinner } from 'react-bootstrap';
import styles from './CancellationPolicy.style';
import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/terms.png';
import Section from '../../Components/Section/Section';

import career from "../../assets/images/career.png";
import Dot from "../../assets/images/dot.png";
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading';
const CancellationPolicy: React.FC = () => {
  const breadcrumbs: string[] = ['Home', 'Cancellation Policy'];
  const title: string = 'Cancellation Policy';
  const info: string = "";
  const imageUrl: string = TitleImage; // Corrected imageUrl



  const navigate = useNavigate()

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);





  return (
    <div className="terms-conditions common-page">

      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className="termsConditions" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>
          <Row>
            <Col>
              <p style={styles.text}>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>

              



            </Col>
          </Row>


        </Container>
      </Section>
    </div>
  );
};

export default CancellationPolicy;
