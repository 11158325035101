import store from "../Redux/Store/Store";

export const getQuantity = (storeId: any,productId:any) => {
const storeData: any = store.getState();

let CartItems = storeData.Cart.View_Cart
// console.log(storeId,"cartItems------->")
    let quantity = 0;
    let foundEle = CartItems?.cart_details?.order?.order_list[storeId];
    if (foundEle) {
        const foundProduct = foundEle.find((ele:any) => ele?.product?.id === productId);
        // console.log(foundProduct,"")
        if (foundProduct) {
            quantity = foundProduct?.quantity;
            // console.log(quantity,"quantity-------->")
            // console.log(quantity,"found-------->")
        }
    }
    return quantity
}

export const handleShowInventoryLabel = (minimumQty:any,maximumQty:any,available:any,quantity:any) => {
    if (minimumQty === quantity) {
     return `MIN ${minimumQty} QUANTITY`
    }
    if (available === quantity) {
     return `ONLY ${available} AVAILABLE`
    }
    if (quantity === maximumQty) {
     return `MAX ${maximumQty} QUANTITY`
    }
    return ""
}