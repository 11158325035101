import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Image,
  Row,
  Form,
  Spinner,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileImage from "../../assets/images/avtar.png";
import EditIcon from "../../assets/images/edit.png";
import SaveIcon from "../../assets/images/saveIcon.png";
import { getStyles } from "./Profile.style";
import { useDispatch, useSelector } from "react-redux";
import { setLoginStatus } from "../../Redux/loginSlice";
import {
  Edit_Address,
  Edit_User_Details,
  Get_User_Details,
  OTP_TO_VERIFY_EMAIL,
  Saved_Address,
  verifyEmail,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import { USER_IMAGE } from "../../Redux/productId";
import { RootState } from "../../Redux/Store/Store";
import Modal from "react-bootstrap/esm/Modal";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { reverseGeocode } from "../../Utils/LocationFinder";

const initialFormData = {
  addressType: "",
};

const Profile: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [userNum, setUserNum] = useState("");
  const [user, setUser] = useState<string | null>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false); // State to track edit mode
  const [profileImage, setProfileImage] = useState<any>(""); // State to store profile image
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [addresses, setAddresses] = useState<any[]>([]);
  const [addressId, setAddressId] = useState("");
  const [email, setEmail] = useState("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [userId, setUserId] = useState<string | null>("");
  const [details, setDetails] = useState<any>({});
  const [imageFile, setImageFile] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isVerified, setIsVerified] = useState<any>("");
  const [emailForVerify, setEmailForVerify] = useState<any>("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [address, setAddress] = useState<any>("");
  const libraries: any = ["places"];
  const googleMapsApiKey: any = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [formData, setFormData] = useState(initialFormData);
  const [storeErrors, setStoreErrors] = useState({
    address:""
  });

  // const [couponId, ] = useState<any>(true);
  const [autocomplete, setAutocomplete] = useState<
    google.maps.places.Autocomplete | any
  >(null);
  const [error, setError] = useState({
    first: "",
    last: "",
    email: "",
  });

  const [storeError, setStoreError] = useState<{
    email?: string;
    image?: string;
  }>({});

  const [userError, setUserError] = useState<{
    // store_name?: string;
    email?: string;
    mobile?: string;
    pincode?: string;
    type?: string;
  }>({});

  const [showEditAddress, setShowEditAddress] = useState<boolean>(false); // State to control visibility of address edit form
  // const [formData, setFormData] = useState({
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   address_str: "",
  //   type: "",
  //   city: "",
  //   state: "",
  //   country: "",
  //   pincode: "",
  //   mobile: "",
  // });
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [emails, setEmails] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [address, setAddress] = useState("");
  // const [city, setCity] = useState("nepal");
  // const [state, setState] = useState("");
  // const [zipCode, setZipCode] = useState("");
  // const [deliveryInstructions, setDeliveryInstructions] = useState("");
  // const [addressType, setAddressType] = useState({});

  const userImage = useSelector(
    (state: RootState) => state.productId.user_image
  );
  const [loading, setLoading] = useState<boolean>(false);
  // const [userError, setUserError] = useState<{
  //   // store_name?: string;
  //   type?: string;
  // }>({});

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setUserId(localStorage.getItem(STORAGE_CONSTANTS.USER_ID));
    // let USERTOKEN = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN)
    // if(!USERTOKEN){
    //   navigate("/login")
    // }
  }, []);

  const handleLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };

  const handleSearchInputChange = (event: any) => {
    const newData = event.target.value;
    setAddress(newData);
    if (newData === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        address: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, address: "" }));
    }
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: event.target.value },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          console.log("predictions--->", predictions);
        }
      }
    );
  };

  const handlePlaceChanged = async () => {
    const details = await autocomplete.getPlace();
    setAddress(details.formatted_address);

    try {
      const { lat, lng } = details.geometry.location;
      const latitude = typeof lat === "function" ? lat() : null;
      const longitude = typeof lng === "function" ? lng() : null;
      console.log(latitude, "lat----------->");

      if (details?.geometry && details?.geometry?.location) {
        handleStartReverseGeocoding(latitude, longitude, googleMapsApiKey);
      }
    } catch (error) {
      console.log("error in selectLocation--->", error);
    }
  };

  const handleStartReverseGeocoding = async (
    latitude: any,
    longitude: any,
    apiKey: any
  ) => {
    // console.log("latitude-->", latitude);
    // console.log("kkkkkkkkkk-->", apiKey);

    try {
      const location: any = await reverseGeocode(latitude, longitude, apiKey);
      console.log(location, "loac=======>");
      if (Object.keys(location)?.length > 0) {
        // setCity(location.cityName);
        // setPincode(location.pincode);
        reset({
          city: location.cityName ?? "",
          zipCode: location.pincode ?? "",
          state: location.state ?? "",
        });
        // setStoreLat(location.lat);
        // setStoreLong(location.long);
      }
      // setLoader(() => false);
      // navigation.goBack();
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
      // setLoader(() => false);
      throw error;
    }
  };

  const emailIsVerified = (isActive: boolean): JSX.Element => {
    return isActive && email ? (
      <div>
        {" "}
        <Image src={SaveIcon} style={styles.editIcon} />
      </div>
    ) : (
      <span onClick={handleVerfiy}>Verify</span>
    );
  };

  const fetchUser = () => {
    const userDetails = async () => {
      try {
        const response = await Get_User_Details();
        if (response && response.status === 200) {
          // console.log(response.data.store_list.language_data[0].store_name);
          console.log(response.data, "response===========>");
          setDetails(response.data.user_details);
          setFirst(response.data.user_details.first_name);
          setLast(response.data.user_details.last_name);
          setUserNum(response.data.user_details.mobile_number);
          setEmail(response.data.user_details.email);
          setEmailForVerify(response.data.user_details.email);
          setProfileImage(response.data.user_details.image);
          setIsVerified(response.data.user_details.is_verified);
          localStorage.setItem(
            STORAGE_CONSTANTS.USER_ID,
            response.data.user_details.id
          );
        } else {
          console.log("Error fetching User Details");
        }
      } catch (error) {
        console.log("Error fetching User Details", error);
      }
    };
    userDetails();
  };

  const SavedAddresses = async () => {
    try {
      const response = await Saved_Address(userToken);
      if (response && response.status === 200) {
        console.log(response.data.list_of_user_address, "saved ----------->");
        setAddresses(response.data.list_of_user_address);
      } else {
        console.log("Error fetching Saved Address");
      }
    } catch (error) {
      console.log("Error fetching Saved Address", error);
    }
  };

  const schema = yup.object().shape({
    firstName: yup.string().required(t("responseMessage.fnRequired")),
    landMark: yup.string().required(t("responseMessage.addressRequired")),
    city: yup.string(),
    state: yup.string(),
    email: yup
      .string()
      .required(t("responseMessage.emailRequired"))
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        t("responseMessage.validEmail")
      ),
    lastName: yup.string().required(t("responseMessage.lnRequired")),
    zipCode: yup
      .string(),
    phoneNumber: yup
      .string()
      .required(t("responseMessage.phoneRequired"))
      .matches(/^\d{10}$/, t("responseMessage.phoneInvalid")),
    instructions: yup.string(),
  });

  const {
    register,
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (userToken) {
      SavedAddresses();
    }
  }, [userToken]);

  useEffect(() => {
    if (userToken && userId) {
      fetchUser();
    }
  }, [userToken, userId]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleClick = () => {
    toast.success(t("responseMessage.userLoggedOut"));
    localStorage.setItem(STORAGE_CONSTANTS.IS_LOGGED_IN, "false");
    dispatch(setLoginStatus(false));
    navigate("/");
  };

  const handleEditClick = () => {
    setEditMode(true); // Enable edit mode upon clicking the edit icon
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setImageFile(file);
    }
  };

  const handleSubmits = async () => {
    if (first === "") {
      setError((error) => ({
        ...error,
        first: t("responseMessage.fnRequired"),
      }));
    }
    if (last === "") {
      setError((error) => ({
        ...error,
        last: t("responseMessage.lnRequired"),
      }));
    }
    if (email === "") {
      setError((error) => ({
        ...error,
        email: t("responseMessage.emailRequired"),
      }));
    }
    // Gather updated profile data from form fields
    if (first && last && email) {
      try {
        const response = await Edit_User_Details(
          first,
          last,
          userNum,
          email,
          imageFile
        );
        if (response && response.status === 200) {
          console.log(response.data, "profile ----------->");
          toast.success(response["data"]["message"]);
          setEditMode(false);
          fetchUser();
          dispatch(USER_IMAGE(!userImage));
          setStoreError({});
        } else {
          console.log("Error adding user details");
        }
      } catch (error: any) {
        console.log("Error fadding user details", error);
        setStoreError(error?.response?.data?.message);
        // toast.error(error.response.data.message[0].image['']);
      } finally {
        setLoading(false);
      }
    } else {
      // toast.error("Please fill out all the fields");
    } // Disable edit mode after saving
  };

  // console.log(profileImage)
  const handleEditAddressClick = (address: any) => {
    setShowEditAddress(true);
    console.log(address, "address------->");
    setAddressId(address.id);
    setAddress(address.address_str)
    reset({
      firstName: address.first_name ?? "",
      lastName: address.last_name ?? "",
      landMark: address.landmark ?? "",
      state: address.state ?? "",
      zipCode: address.pincode ?? "",
      city: address.city ?? "",
      email: address.email ?? "",
      phoneNumber: address.mobile ?? "",
      // instructions: address
    });
    setFormData((prevData) => ({
      ...prevData,
      addressType: address.type ?? "",
    }));    
    resetErrors();
  };

  const handleCloseEditAddress = () => {
    setShowEditAddress(false);
  };

  const handleAddressSubmit = async (data: any) => {
    let formIsValid = true

    if (address === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        address: `* This field is required`,
      }));
      formIsValid = false;
    }
    if (data && formIsValid) {
      try {
        const response = await Edit_Address(data,formData,addressId,address);
        if (response && response.status === 202) {
          console.log(response.data, "added address ----------->");
          SavedAddresses();
          handleCloseEditAddress();
          toast.success(response["data"]["messsage"]);
        } else {
          console.log("Error fetching Added Address");
        }
      } catch (error: any) {
        console.log("Error fetching Added Address", error);
        // toast.error(error.response.data.error);
        setUserError(error.response.data.error);
      }
    } else {
      toast.error(t("responseMessage.fillAll"));
    }
  };

  useEffect(() => {
    if (profileImage) {
      setProfileImage(profileImage);
    }
  }, [profileImage]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetErrors = () => {
    setUserError({ email: "" });
  };

  const handleFirst = (e: any) => {
    let newValue = e.target.value.trimStart();
    setFirst(newValue);
    let alpha = /^[a-zA-Z\s]+$/;

    if (newValue === "") {
      setError((error) => ({
        ...error,
        first: t("responseMessage.fnRequired"),
      }));
    } else if (!alpha.test(newValue)) {
      setError((error) => ({
        ...error,
        first: t("responseMessage.fnValid"),
      }));
    } else {
      setError((error) => ({
        ...error,
        first: "",
      }));
    }
  };

  const handleLast = (e: any) => {
    let newValue = e.target.value.trimStart();
    setLast(newValue);
    let alpha = /^[a-zA-Z\s]+$/;

    if (newValue === "") {
      setError((error) => ({
        ...error,
        last: t("responseMessage.lnRequired"),
      }));
    } else if (!alpha.test(newValue)) {
      setError((error) => ({
        ...error,
        last: t("responseMessage.lnValid"),
      }));
    } else {
      setError((error) => ({
        ...error,
        last: "",
      }));
    }
  };

  const handleEmail = (e: any) => {
    let newValue = e.target.value.trimStart();
    setEmail(newValue);
    let alpha = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (newValue === "") {
      setError((error) => ({
        ...error,
        email: t("responseMessage.emailRequired"),
      }));
    } else if (!alpha.test(newValue)) {
      setError((error) => ({
        ...error,
        email: t("responseMessage.validEmail"),
      }));
    } else {
      setError((error) => ({
        ...error,
        email: "",
      }));
    }
  };

  const handleSendOtpForVerification = async () => {
    // setLoader(true)
    try {
      const response: any = await OTP_TO_VERIFY_EMAIL();

      if (response && response.status === 200) {
        setIsOtpSent(true);
        toast.success(response["data"]["message"]);
      }
      // setLoader(false)
    } catch (error: any) {
      if (error?.response?.data?.error_message) {
        toast.error(error?.response?.data?.error_message);
      } else if (error?.message) {
        toast.error(error?.message);
      }
      // setLoader(false)
    }
  };

  const handleVerifyOtpForVerification = async () => {
    // setLoader(true)
    try {
      const response: any = await verifyEmail(verifyOTP);

      if (response && response.status === 200) {
        // setIsOtpSent(true);
        toast.success(response["data"]["message"]);
        setVerifyOTP("");
        setShowCancelModal(false);
        fetchUser();
        setEditMode(false);
      }
      // setLoader(false)
    } catch (error: any) {
      if (error?.response?.data?.error_message) {
        toast.error(error?.response?.data?.error_message);
      } else if (error?.message) {
        toast.error(error?.message);
      }
      // setLoader(false)
    }
  };

  const handleVerfiy = async () => {
    let hasError = false;

    if (first === "") {
      setError((error) => ({
        ...error,
        first: t("responseMessage.fnRequired"),
      }));
      hasError = true;
    }
    if (last === "") {
      setError((error) => ({
        ...error,
        last: t("responseMessage.lnRequired"),
      }));
      hasError = true;
    }
    if (email === "") {
      setError((error) => ({
        ...error,
        email: t("responseMessage.emailRequired"),
      }));
      hasError = true;
    }

    // If there are validation errors, exit early
    if (hasError) {
      return;
    }

    setLoading(true);

    try {
      if (first && last && email) {
        const response = await Edit_User_Details(
          first,
          last,
          userNum,
          email,
          imageFile
        );

        if (response && response.status === 200) {
          console.log(response.data, "profile ----------->");
          toast.success(response.data.message);
          // setEditMode(false);
          fetchUser();
          dispatch(USER_IMAGE(!userImage));
          setStoreError({});
          handleSendOtpForVerification();
          setShowCancelModal(true);
        } else {
          console.log("Error adding user details");
          toast.error("Error adding user details");
        }
      } else {
        handleSendOtpForVerification();
        setShowCancelModal(true);
      }
    } catch (error: any) {
      console.log("Error adding user details", error);
      setStoreError(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // console.log(Object.keys(imageFile).length, "addressid--------->");
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <h3 className="tab-title mb-0">{t("userProfile.myProfile")}</h3>
        {editMode ? (
          <Button style={styles.editIconWrap} onClick={handleSubmits}>
            <Image src={SaveIcon} style={styles.editIcon} />{" "}
            {t("userProfile.save")}
          </Button>
        ) : (
          <Button style={styles.editIconWrap} onClick={handleEditClick}>
            <Image src={EditIcon} style={styles.editIcon} />{" "}
            {t("userProfile.edit")}
          </Button>
        )}
      </div>

      {/* Profile Image and Basic Info */}
      <div
        style={styles.basicInfo}
        className="d-flex align-items-center profile-row"
      >
        <div style={styles.profileImageWrap}>
          {editMode ? (
            <>
              <label htmlFor="profileImageInput">
                <Image
                  src={
                    selectedImage
                      ? selectedImage
                      : profileImage
                      ? `${base_url}${profileImage}`
                      : ProfileImage
                  }
                  style={styles.profileImage}
                />
                <input
                  id="profileImageInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </label>
            </>
          ) : (
            <Image
              src={details.image ? `${base_url}${details.image}` : ProfileImage}
              style={styles.profileImage}
            />
          )}
        </div>
        <div style={styles.info}>
          {editMode ? (
            <>
              <h3 style={styles.name}>{t("userProfile.imageUpload")}</h3>
              {storeError && <span className="err">{storeError.image}</span>}
            </>
          ) : (
            <>
              <h3 style={styles.name}>
                {details.first_name
                  ? `${details.first_name} ${details.last_name}`
                  : "Your Name"}
              </h3>
              <h3 style={styles.phone}>+91 {details.mobile_number}</h3>

              <h3 style={styles.email}>
                {details.email ? details.email : "abc@xyz.com"}
              </h3>
            </>
          )}
        </div>
      </div>

      {/* Basic Info Section */}
      {editMode && (
        <div style={styles.basicInfo} className="mt-3 basicInfo">
          <div>
            <h3 className="tab-title">{t("userProfile.myProfile")}</h3>
          </div>

          <Row>
            <Col sm={4}>
              <label style={styles.label}>
                {t("commonFormLabels.firstName")}
              </label>
              {editMode ? (
                <>
                  <Form.Control
                    type="text"
                    defaultValue={t("commonFormLabels.firstName")}
                    value={first}
                    onChange={handleFirst}
                  />
                  {error && <span className="err">{error.first}</span>}
                </>
              ) : (
                <p style={styles.value}>{t("responseMessage.enterFn")}</p>
              )}
            </Col>
            <Col sm={4}>
              <label style={styles.label}>
                {t("commonFormLabels.lastName")}
              </label>
              {editMode ? (
                <>
                  <Form.Control
                    type="text"
                    defaultValue={t("commonFormLabels.lastName")}
                    value={last}
                    onChange={handleLast}
                  />
                  {error && <span className="err">{error.last}</span>}
                </>
              ) : (
                <p style={styles.value}>{t("responseMessage.enterLn")}</p>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={4}>
              <label style={styles.label}>
                {t("commonFormLabels.phoneNumber")}
              </label>
              <Form.Control type="text" value={userNum} readOnly disabled />
              {/* {storeError && (
                <span style={{ color: "red" }}>{storeError.mobile_number}</span>
              )} */}
            </Col>
            <Col sm={4}>
              <label style={styles.label}>{t("commonFormLabels.email")}</label>
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "22rem",
                  }}
                >
                  <div>
                    <Form.Control
                      type="email"
                      defaultValue="lorem@ipsumdolorsit"
                      value={email}
                      onChange={handleEmail}
                    />
                    {storeError && (
                      <span className="err">{storeError.email}</span>
                    )}
                    {error && <span className="err">{error.email}</span>}
                  </div>
                  <div>
                    <div
                      style={{
                        cursor: "pointer",
                        paddingBottom: "1rem",
                        fontSize: "small",
                        color: "blue",
                      }}
                    >
                      {emailIsVerified(isVerified)}
                    </div>
                  </div>
                </div>
              </>
              <div>
                <Modal
                  show={showCancelModal}
                  onHide={() => setShowCancelModal(false)}
                  centered
                  className="cancelOrderModal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t("cancelOrder.cancelOrder")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="cancelReason">
                        <Form.Label>{`OTP sent to  ${email}`}</Form.Label>
                        <br />
                        <Form.Label>{`Enter your OTP`}:</Form.Label>
                        <Form.Control
                          type="number"
                          // rows={3}
                          //  placeholder={t("userProfile.enterCancelReason")}
                          value={verifyOTP}
                          onChange={(e) => setVerifyOTP(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btnCancel"
                      onClick={() => setShowCancelModal(false)}
                    >
                      {"cancel"}
                    </Button>
                    <Button
                      className="btnBlue"
                      disabled={verifyOTP.length > 6 || verifyOTP.length < 6}
                      onClick={handleVerifyOtpForVerification}
                    >
                      {"submit"}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {/* Address Section */}
      {addresses.length > 0 && (
        <div style={styles.basicInfo} className="mt-3 address-wrap">
          <div>
            <h3 className="tab-title">{t("userProfile.address")}</h3>
          </div>
          <Row className="addressWrap">
            {addresses &&
              addresses.map((address, index) => {
                return (
                  <Col sm="4" className="mb-3" style={styles.addressRadio}>
                    <Form.Group>
                      <Form.Check
                        type="radio"
                        id="radioOption1"
                        name="radioOptions"
                        value="option1"
                        checked={selectedOption === "option1"}
                        onChange={handleOptionChange}
                        label={
                          <div style={styles.radioContent}>
                            <h5 style={styles.radioTitle}>{address.type}</h5>
                            <div style={styles.addressDescriptionText}>
                              <b>
                                {address.first_name} {address.last_name}
                              </b>{" "}
                              <br></br>
                              {address.address_str} <br></br>
                              {address.city} {address.state} <br></br>
                              {address.country} {address.pincode}{" "}
                            </div>
                            <Button
                              style={styles.addressEditIconWrap}
                              onClick={() => handleEditAddressClick(address)}
                            >
                              <Image
                                src={EditIcon}
                                style={{ ...styles.editIcon, top: 24 }}
                              />
                            </Button>
                          </div>
                        }
                      />
                    </Form.Group>
                  </Col>
                );
              })}

            {/* <Form.Group>
              <Form.Check
                type="radio"
                id="radioOption2"
                name="radioOptions"
                value="option2"
                checked={selectedOption === "option2"}
                onChange={handleOptionChange}
                label={
                  <div style={styles.radioContent}>
                    <h5 style={styles.radioTitle}>Work</h5>
                    <p style={styles.radioInfo}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <Button style={styles.addressEditIconWrap} onClick={handleEditAddressClick}>
                      <Image
                        src={EditIcon}
                        style={{ ...styles.editIcon, top: 24 }}
                      />
                    </Button>
                  </div>
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="radio"
                id="radioOption3"
                name="radioOptions"
                value="option3"
                checked={selectedOption === "option3"}
                onChange={handleOptionChange}
                label={
                  <div style={styles.radioContent}>
                    <h5 style={styles.radioTitle}>Other</h5>
                    <p style={styles.radioInfo}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <Button style={styles.addressEditIconWrap} onClick={handleEditAddressClick}>
                      <Image
                        src={EditIcon}
                        style={{ ...styles.editIcon, top: 24 }}
                      />
                    </Button>
                  </div>
                }
              />
            </Form.Group> */}
          </Row>
        </div>
      )}

      {/* Submit Button */}
      {/* {editMode && (
        <div className="d-flex justify-content-end">
          <Button style={styles.submitBtn}>Submit</Button>
        </div>
      )} */}

      {/* Delete Account Section */}
      <div style={styles.deleteWrap}>
        <h3 style={styles.deleteTitle}>{t("userProfile.deleteAccount")}</h3>
        <p style={styles.deleteInfo}>{t("userProfile.deleteAccountInfo")}</p>
      </div>

      {/* Address Edit Form */}
      <Modal size="lg" show={showEditAddress} onHide={handleCloseEditAddress}>
        <Modal.Header closeButton>
          <Modal.Title className="tab-title">
            {t("commonFormLabels.editAddress")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit(handleAddressSubmit)}
            className="billingInfo"
          >
            <Row>
              <Col>
                <FormGroup controlId="formFirstName">
                  <FormLabel className="d-block">
                    {t("commonFormLabels.firstName")}
                  </FormLabel>
                  <FormControl
                    type="text"
                    placeholder={t("commonFormLabels.firstName")}
                    // name="firstName"
                    // value={formData.firstName}
                    // onChange={handleChange}
                    // required
                    {...register("firstName")}
                  />
                  {errors.firstName && (
                    <span style={styles.error}>{errors.firstName.message}</span>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formLastName">
                  <FormLabel className="d-block">
                    {t("commonFormLabels.lastName")}
                  </FormLabel>
                  <FormControl
                    type="text"
                    placeholder={t("commonFormLabels.lastName")}
                    // name="lastName"
                    // value={formData.lastName}
                    // onChange={handleChange}
                    // required
                    {...register("lastName")}
                  />
                  {errors.lastName && (
                    <span style={styles.error}>{errors.lastName.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup controlId="formEmail">
                  <FormLabel className="d-block">
                    {t("commonFormLabels.email")}
                  </FormLabel>
                  <FormControl
                    type="email"
                    placeholder={t("commonFormLabels.email")}
                    // name="email"
                    // value={formData.email}
                    // onChange={handleChange}
                    // required
                    {...register("email")}
                  />
                  {errors.email && (
                    <span style={styles.error}>{errors.email.message}</span>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formPhoneNumber">
                  <FormLabel className="d-block">
                    {t("commonFormLabels.phoneNumber")}
                  </FormLabel>
                  <FormControl
                    type="text"
                    placeholder={t("commonFormLabels.phoneNumber")}
                    // name="phoneNumber"
                    // value={formData.phoneNumber}
                    // onChange={handleChange}
                    // required
                    // maxLength={10}
                    {...register("phoneNumber")}
                  />
                  {errors.phoneNumber && (
                    <span style={styles.error}>
                      {errors.phoneNumber.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="searchAddress">
                  <Form.Label className="d-block">
                    {"Address"}
                  </Form.Label>
                  <div className="locationAutoComplete">
                    <LoadScript
                      googleMapsApiKey={googleMapsApiKey}
                      libraries={libraries}
                    >
                      <Autocomplete
                        onLoad={handleLoad}
                        onPlaceChanged={handlePlaceChanged}
                      >
                        <div id="location-popup">
                          <Form.Control
                            id="location-search"
                            type="text"
                            placeholder="Search for places..."
                            className="btnWhite"
                            value={address}
                            onChange={handleSearchInputChange}
                          />
                          {storeErrors.address && (
                            <span style={styles.error}>
                              {storeErrors.address}
                            </span>
                          )}
                        </div>
                      </Autocomplete>
                    </LoadScript>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup controlId="formAddress">
                  <FormLabel className="d-block">
                    {"LandMark"}
                  </FormLabel>
                  <FormControl
                    as="textarea"
                    placeholder={"Enter your landmark"}
                    // name="address"
                    // value={formData.address}
                    // onChange={handleChange}
                    // required
                    {...register("landMark")}
                  />
                  {errors.landMark && (
                    <span style={styles.error}>{errors.landMark.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup controlId="formCity">
                  <FormLabel className="d-block">
                    {t("commonFormLabels.city")}
                  </FormLabel>
                  <FormControl
                    type="text"
                    placeholder={t("commonFormLabels.city")}
                    // name="city"
                    // value={formData.city}
                    // onChange={handleChange}
                    // required
                    {...register("city")}
                    disabled
                  />
                  {/* {errors.city && (
                    <span style={styles.error}>{errors.city.message}</span>
                  )} */}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formState">
                  <FormLabel className="d-block">
                    {t("commonFormLabels.state")}
                  </FormLabel>
                  {/* <FormControl
                                    as="select"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="">Select State</option>
                                    <option value="mh">MH</option>
                                  </FormControl>  */}
                  <FormControl
                    type="text"
                    placeholder={t("commonFormLabels.state")}
                    // name="state"
                    // value={formData.state}
                    // onChange={handleChange}
                    // required
                    {...register("state")}
                    disabled
                  />
                  {/* {errors.state && (
                    <span style={styles.error}>{errors.state.message}</span>
                  )} */}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formZipCode">
                  <FormLabel className="d-block">
                    {t("commonFormLabels.zipCode")}
                  </FormLabel>
                  <FormControl
                    type="text"
                    placeholder={t("commonFormLabels.zipCode")}
                    // name="zipCode"
                    // value={formData.zipCode}
                    // onChange={handleChange}
                    // required
                    // // maxLength={6}
                    // maxLength={2}
                    {...register("zipCode")}
                    disabled
                  />
                  {/* {errors.zipCode && (
                    <span style={styles.error}>{errors.zipCode.message}</span>
                  )} */}
                </FormGroup>
              </Col>
            </Row>
            {/* <FormGroup controlId="formDeliveryInstructions">
              <FormLabel className="d-block">
                {t("commonFormLabels.deliveryInstruction")}
              </FormLabel>
              <FormControl
                as="textarea"
                placeholder={t("commonFormLabels.deliveryInstruction")}
                {...register("instructions")}
              />
            </FormGroup> */}
            <FormGroup controlId="formAddressType" className="address-type">
              <FormLabel className="d-block">
                {t("commonFormLabels.addressType")}
              </FormLabel>
              <div className="btn-group type-btn">
                <Button
                  className={`btn ${
                    formData.addressType === "home"
                      ? "btn-primary"
                      : "btn-light"
                  }`}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      addressType: "home",
                    }))
                  }
                >
                  {t("commonFormLabels.home")}
                </Button>
                <Button
                  className={`btn ${
                    formData.addressType === "work"
                      ? "btn-primary"
                      : "btn-light"
                  }`}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      addressType: "work",
                    }))
                  }
                >
                  {t("commonFormLabels.work")}
                </Button>
                <Button
                  className={`btn ${
                    formData.addressType === "other"
                      ? "btn-primary"
                      : "btn-light"
                  }`}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      addressType: "other",
                    }))
                  }
                >
                  {t("commonFormLabels.other")}
                </Button>
              </div>
              <br />
              {userError.type && <span className="err">{userError.type}</span>}
            </FormGroup>
            <Row className="align-items-center justify-content-center d-flex my-3">
              <Button
                className="btnBlue"
                style={{ maxWidth: 400 }}
                type="submit"
              >
                {t("cart.addAddress")}
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Profile;
