// Header.style.ts

import { CSSProperties } from "react";

export interface HeaderStyles {
  header: CSSProperties;
  logo: CSSProperties;
  login: CSSProperties;
  navLink: CSSProperties;
  or: CSSProperties;
  modalTitle: CSSProperties;
  modalInfo: CSSProperties;
  modalClick:CSSProperties;
  globeIcon:CSSProperties;
}

const styles: HeaderStyles = {
  header: {
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #e0e0e0",
    padding: "10px 0",
  },
  logo: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333333",
    textDecoration: "none",
  },
  
  navLink: {
    marginRight: "15px",
    color: "#333333",
    textDecoration: "none",
    
  },
  login: {
    borderColor: "var(--primary-color)",
    borderWidth: 1,
    borderStyle: "solid",
    color: "var(--primary-color)",
    borderRadius: 16,
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--white-color)",
  },
  or: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "#808080",
  },
  modalTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 24,
    color: "var(--text-color)",
    marginBottom: 12,
  },
  modalInfo:{
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "#808080",
    marginBottom: 20,
  },
  modalClick :{
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 16,
    marginBottom:0,
    marginRight:5,
    marginLeft:5,
    textTransform:'capitalize',
    color:  "var(--text-color)",
  },
  globeIcon :{
    borderRightWidth:1,
    borderRightStyle:"solid",
borderRightColor:"#b0b0b0",
paddingRight:20

  }

};

export default styles;
